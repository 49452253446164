import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { SelectMenu, Button, Position } from 'evergreen-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons';

import { updateHost } from '@/services/actions/filter';

const SiteListDropdown = ({
  className,
  title,
  onChange,
  excludeAllSites,
  fromMenu = false,
}) => {
  const dispatch = useDispatch();
  const router = useRouter();

  const { pathname, query } = router;

  const siteList = useSelector(({ session }) => session.sites);
  const filterStore = useSelector(({ filter }) => filter);
  const filterHost = filterStore.host;
  const [host, setHost] = useState(
    query.tab === 2 && window.location.href.includes('settings')
      ? ''
      : filterHost,
  );

  const [filterValue, setFilterValue] = useState('');

  useEffect(() => {
    if (filterHost !== host) {
      if (window.location.href.includes('settings') && +query.tab === 2) {
        setHost('');
        dispatch(updateHost(''));
      } else {
        setHost(filterHost);
        dispatch(updateHost(query.host));
      }
    }

    if (
      window.location.href.split('host=')[1] &&
      !window.location.href.split('host=')[1].split('&')[0] &&
      host
    ) {
      const updatedQuery = { ...router.query, host };
      router.replace({
        pathname: router.pathname,
        query: updatedQuery,
      });
    }

    if (
      (window.location.href.includes('revenue/?by=domainanddate&') ||
        window.location.href.includes('cloud-messaging')) &&
      host !== query.host
    ) {
      setHost(query.host);
      dispatch(updateHost(query.host));
    }
  }, [filterHost, query.host]);

  const options =
    excludeAllSites || siteList.length <= 1
      ? [...siteList.map((label) => ({ label, value: label }))]
      : [
          { label: 'All Sites', value: '' },
          ...siteList.map((label) => ({ label, value: label })),
        ];

  useEffect(() => {
    if (
      !host &&
      !query.host &&
      options.length === siteList.length &&
      (window.location.href.includes('settings') ? +query.tab !== 2 : true)
    ) {
      query.host = options[0]?.value;
    }
  }, [host, query.host, query.tab, options.length]);

  const handleChange = (item) => {
    setHost(item.value);
    dispatch(updateHost(item.value));

    query.host = item.value;
    router.push({ pathname, query });

    if (onChange && typeof onChange === 'function') {
      onChange(item.value);
    }

    if (query && query.by && query.by === 'adunits') {
      query.graph = '';
      query.adUnit = '';
      query.category = '';
      query.device = '';
      router.push({ pathname, query });
    }
  };

  if (!siteList) {
    return null;
  }

  return (
    <SelectMenu
      className={className}
      onClose={() => setFilterValue('')}
      title={title || 'Select a site...'}
      selected={
        host ||
        query.host ||
        (options.length === siteList.length && options[0]?.value) ||
        ''
      }
      options={options.filter((el) =>
        el.label.toLowerCase().includes(filterValue.toLowerCase()),
      )}
      minWidth={240}
      onFilterChange={(e) => setFilterValue(e)}
      onSelect={handleChange}
      position={Position.BOTTOM}
      closeOnSelect={true}
      trigger={fromMenu ? 'click' : 'hover'}
    >
      <Button
        paddingLeft={0}
        paddingRight={15}
        display="block"
        height="auto"
        appearance="minimal"
        intent="none"
        fontSize={16}
        className="site-list__button outline-none-f mg-r-25"
      >
        {host || title || query.host || 'All Sites'}
        <FontAwesomeIcon icon={faAngleDown} />
      </Button>
    </SelectMenu>
  );
};

export default SiteListDropdown;
