import {useEffect, useMemo, useRef} from "react";
import debounce from "lodash/debounce";

const useDebounce = (callback, waitTime = 1000) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  return useMemo(() => {
    const func = () => {
      ref.current?.();
    };

    return debounce(func, waitTime);
  }, [waitTime]);
};

export default useDebounce;
