import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Modal } from 'react-bootstrap';
import { Popover } from 'evergreen-ui';
import { useState, useEffect, useRef } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';

import {
  fuzzySearchData,
  subTabInformation,
} from '@/scenes/Settings/searchData';
import useDebounce from '@/hooks/useDebounce';

const SearchItemCard = ({ searchItem, onNavigation }) => {
  const router = useRouter();

  const [idToScrollTo, setIdToScrollTo] = useState('');

  useEffect(() => {
    const onRouteChangeComplete = () => {
      if (idToScrollTo) {
        if (typeof requestIdleCallback === 'function') {
          requestIdleCallback(
            () => {
              const element = document.getElementById(idToScrollTo);
              if (element) {
                element.scrollIntoView();
              }
            },
            { timeout: 1000 },
          );
        } else {
          setTimeout(() => {
            const element = document.getElementById(idToScrollTo);
            if (element) {
              element.scrollIntoView();
            }
          }, 1000);
        }
      }
    };

    router.events.on('routeChangeComplete', onRouteChangeComplete);
    return () => {
      router.events.off('routeChangeComplete', onRouteChangeComplete);
    };
  }, [router, idToScrollTo]);

  const handleNavigation = (e, subtTabItem) => {
    e.preventDefault();
    setIdToScrollTo(subtTabItem.id || '');
    onNavigation();
    router.push(subtTabItem.path);
  };

  return (
    <Card>
      <Card.Body>
        <Card.Title>
          <Link
            onClick={(e) => {
              handleNavigation(e, subTabInformation[searchItem]);
            }}
            className="settings-searcher-link"
            href={subTabInformation[searchItem].path}
          >
            {searchItem}
          </Link>
        </Card.Title>
        <Card.Subtitle>
          <p className="text-muted">{subTabInformation[searchItem].route}</p>
        </Card.Subtitle>
        <Card.Text>
          {subTabInformation[searchItem].description &&
            subTabInformation[searchItem].description}
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

const SettingsSearcher = () => {
  const [searchText, setSearchText] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [showModal, setShowModal] = useState(false);
  const inputRef = useRef(null);
  const containerRef = useRef(null);
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const isClickInsideInput = inputRef.current?.contains(event.target);
      const isClickInsideContainer = containerRef.current?.contains(
        event.target,
      );
      const isClickInsideModal = modalRef.current?.contains(event.target);

      if (
        !isClickInsideInput &&
        !isClickInsideContainer &&
        !isClickInsideModal
      ) {
        setSearchText('');
        setShowModal(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const debouncedSearch = useDebounce(() => {
    const fuzzySearchResults = fuzzySearchData.search(searchText);
    const searchResultItems = [];
    fuzzySearchResults.forEach((item) => {
      searchResultItems.push(item.item);
    });
    setSearchResults(searchResultItems);
  }, 500);

  useEffect(() => {
    debouncedSearch();
  }, [searchText]);

  useEffect(() => {
    function onResizeListener() {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener('resize', onResizeListener);
    return () => {
      window.removeEventListener('resize', onResizeListener);
    };
  }, []);

  const resetState = () => {
    setSearchResults([]);
    setSearchText('');
    setShowModal(false);
  };

  if (screenWidth > 768) {
    return (
      <Popover
        isShown={searchResults.length > 0}
        content={
          <div className="settings-searcher-container" ref={containerRef}>
            <h5 className="settings-searcher-heading">
              Search results: {searchResults.length} item(s)
            </h5>
            {searchResults.map((searchItem) => (
              <SearchItemCard
                onNavigation={() => resetState()}
                searchItem={searchItem}
                key={searchItem}
              />
            ))}
          </div>
        }
        position="bottom-right"
      >
        <div className="pos-relative pr-2">
          <input
            type="search"
            ref={inputRef}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder="Search Settings..."
            className="form-control search-input settings-searcher-input"
          />
          <FontAwesomeIcon icon={faSearch} className="pos-absolute" />
        </div>
      </Popover>
    );
  }

  return (
    <>
      <Button
        className="settings-searcher-mobile-button mr-2"
        onClick={() => setShowModal(true)}
      >
        <span className="ml-2">
          <FontAwesomeIcon icon={faSearch} />
        </span>
        <span className="text-truncate mg-l-10">
          {screenWidth > 420
            ? 'Search Settings'
            : screenWidth > 370
            ? 'Settings'
            : ''}
        </span>
      </Button>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>Search Settings</Modal.Header>
        <Modal.Body className="pb-3 pt-1" ref={modalRef}>
          <div className="pos-relative">
            <input
              type="search"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              placeholder="Search Settings..."
              className="form-control search-input w-100"
              ref={inputRef}
            />
            <FontAwesomeIcon icon={faSearch} className="pos-absolute" />
          </div>
          {searchResults.length > 0 && (
            <h5 className="settings-searcher-heading p-3">
              Search results: {searchResults.length} item(s)
            </h5>
          )}
          {searchResults.map((searchItem) => (
            <SearchItemCard
              onNavigation={() => resetState()}
              searchItem={searchItem}
              key={searchItem}
            />
          ))}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SettingsSearcher;
