import Fuse from 'fuse.js';

const subTabs = [
  'Profile',
  'Admin Email',
  'Change Admin Password',
  'Moderation',
  'Stop comments/replies from appearing on article page before approval',
  'Send comments with links to moderation',
  'Send comments with images to moderation',
  'Send comments with emojis to moderation',
  'Block words exact match',
  'OpenAI comment scanning',
  'Privacy',
  'Custom Checkbox',
  'Privacy checkbox text',
  'Privacy Policy link',
  'Privacy Policy link text',
  'Notification',
  'Admin',
  'All moderators',
  'Login Settings',
  'Google',
  'Twitter',
  'Facebook',
  'Disqus',
  'SSO',
  'General Settings',
  'Show newsletter popup',
  'Toggle hiding the comments widget under a button',
  'Hide comment input box',
  'Enable comment panel',
  'Number of reports after which a comment will be flagged for moderation',
  'Default count of loaded comments on the page',
  'Comment Character Limit',
  'Selected RTE options',
  'Spam limit',
  'Toxicity limit',
  'Theme Color',
  'Toggle dark mode theme',
  'Open Talk of Town articles in the same tab',
  'Emotes header label',
  'Emotes vote label',
  'Enable ToT Landscape Images',
  'Talk of Town label',
  'Talk Of Town default article count',
  'Talk Of Town default interval count(days)',
  'Default sorting option for comments',
  'Update external search results source for comments',
  'Enable or disable comment bubble button',
  'Enable or disable comments for some article',
  'Get user comments CSV file by user email',
  'Currently enabled widgets',
  'Whitelist commenter(s) by Email ID',
  'Block word(s)',
  'Block username(s)',
  'Block commenter(s) by IP Address',
  'Block commenter(s) by Email ID',
  'User Management',
  'Emotes Widget Settings',
  'Emotes Icons Size (px)',
  'Hide emotes recommended articles.',
  'Hide first emote',
  'Hide second emote',
  'Hide third emote',
  'Hide fourth emote',
  'Hide fifth emote',
  'Hide sixth emote',
  'Custom Emote Names and Images',
  'First Emote Name',
  'Second Emote Name',
  'Third Emote Name',
  'Fourth Emote Name',
  'Fifth Emote Name',
  'Sixth Emote Name',
  'First Emote Image Link',
  'Second Emote Image Link',
  'Third Emote Image Link',
  'Fourth Emote Image Link',
  'Fifth Emote Image Link',
  'Sixth Emote Image Link',
  'Shares Widget',
  'Horizontal Position',
  'Vertical Position (px)',
  'Default Emote',
  'Disable audio player',
  'Disable sharebar emote icon',
  'Disable bookmark button',
  'Disable sharebar more shares button',
  'Disable comments button',
  'Disable PDF download button',
  'Enable custom sharebar styles',
  'Google news URL',
  'Instagram URL',
  'Social Networks',
  'Quiz widget general settings',
  'Toggle Quiz widget and Talk of Town popup',
  'Disable results of the Quiz answers',
  'Disable quiz next button',
  'Disable quiz topics',
  'Hide quiz default questions',
  'Change the quiz answers count',
  'Change the quiz AI questions count (min 1, max 7)',
  'Change the quiz default questions count (min 1, max 3)',
  'Change the quiz brand questions start index',
  'Upload Logo',
  'Block Words (Quiz)',
  'Quiz brands',
  'Quiz brand questions',
];

export const fuzzySearchData = new Fuse(subTabs, {
  isCaseSensitive: false,
});

export const subTabInformation = {
  Profile: {
    path: '/settings/?tab=0&subtab=0',
    route: 'Settings -> Profile Settings',
    description: 'Your personal information',
    id: 'profile-settings',
  },
  'Admin Email': {
    path: '/settings/?tab=0&subtab=1',
    route: 'Settings -> Profile Settings -> Profile',
    description: 'This email is used to login to dashboard',
    id: 'admin-email-settings',
  },
  'Change Admin Password': {
    path: '/settings/?tab=0&subtab=2',
    route: 'Settings -> Profile Settings -> Profile',
    description: 'Change your password',
    id: 'change-admin-password-settings',
  },
  Moderation: {
    path: '/settings/?tab=1&subtab=0',
    route: 'Settings -> Comments Widget',
    description: 'Configure your comment moderation settings',
    id: 'moderation-settings',
  },
  'Stop comments/replies from appearing on article page before approval': {
    path: '/settings/?tab=1&subtab=0',
    route: 'Settings -> Comments Widget -> Moderation',
    id: 'moderation-settings',
  },
  'Send comments with links to moderation': {
    path: '/settings/?tab=1&subtab=0',
    route: 'Settings -> Comments Widget -> Moderation',
    id: 'moderation-settings',
  },
  'Send comments with images to moderation': {
    path: '/settings/?tab=1&subtab=0',
    route: 'Settings -> Comments Widget -> Moderation',
    id: 'moderation-settings',
  },
  'Send comments with emojis to moderation': {
    path: '/settings/?tab=1&subtab=0',
    route: 'Settings -> Comments Widget -> Moderation',
    id: 'moderation-settings',
  },
  'Block words exact match': {
    path: '/settings/?tab=1&subtab=0',
    route: 'Settings -> Comments Widget -> Moderation',
    id: 'moderation-settings',
  },
  'OpenAI comment scanning': {
    path: '/settings/?tab=1&subtab=0',
    route: 'Settings -> Comments Widget -> Moderation',
    id: 'moderation-settings',
  },
  Privacy: {
    path: '/settings/?tab=1&subtab=1',
    route: 'Settings -> Comments Widget',
    description: 'Configure your privacy settings',
    id: 'privacy-settings',
  },
  'Custom Checkbox': {
    path: '/settings/?tab=1&subtab=1',
    route: 'Settings -> Comments Widget -> Privacy',
    id: 'privacy-settings',
  },
  'Privacy checkbox text': {
    path: '/settings/?tab=1&subtab=1',
    route: 'Settings -> Comments Widget -> Privacy',
    id: 'privacy-settings',
  },
  'Privacy Policy link': {
    path: '/settings/?tab=1&subtab=1',
    route: 'Settings -> Comments Widget -> Privacy',
    id: 'privacy-settings',
  },
  'Privacy Policy link text': {
    path: '/settings/?tab=1&subtab=1',
    route: 'Settings -> Comments Widget -> Privacy',
    id: 'privacy-settings',
  },
  Notification: {
    path: '/settings?tab=1&subtab=2',
    route: 'Settings -> Comments Widget',
    description: 'Allow email notification for every comment reply',
    id: 'notification-settings',
  },
  Admin: {
    path: '/settings?tab=1&subtab=2',
    route: 'Settings -> Comments Widget -> Notification',
    id: 'notification-settings',
  },
  'All moderators': {
    path: '/settings?tab=1&subtab=2',
    route: 'Settings -> Comments Widget -> Notification',
    id: 'notification-settings',
  },
  'Login Settings': {
    path: '/settings?tab=1&subtab=3',
    route: 'Settings -> Comments Widget',
    description: 'Enable/Disable auth methods for Vuukle comments widget',
    id: 'login-settings',
  },
  'Vuukle Password': {
    path: '/settings?tab=1&subtab=3',
    route: 'Settings -> Comments Widget -> Login Settings',
    id: 'login-settings',
  },
  Google: {
    path: '/settings?tab=1&subtab=3',
    route: 'Settings -> Comments Widget -> Login Settings',
    id: 'login-settings',
  },
  Twitter: {
    path: '/settings?tab=1&subtab=3',
    route: 'Settings -> Comments Widget -> Login Settings',
    id: 'login-settings',
  },
  Facebook: {
    path: '/settings?tab=1&subtab=3',
    route: 'Settings -> Comments Widget -> Login Settings',
    id: 'login-settings',
  },
  Disqus: {
    path: '/settings?tab=1&subtab=3',
    route: 'Settings -> Comments Widget -> Login Settings',
    id: 'login-settings',
  },
  SSO: {
    path: '/settings?tab=1&subtab=3',
    route: 'Settings -> Comments Widget -> Login Settings',
    id: 'login-settings',
  },
  'General Settings': {
    path: '/settings?tab=1&subtab=4',
    route: 'Settings -> Comments Widget',
    description: 'Customize some aspects of Vuukle comments widget',
    id: 'general-comments-settings',
  },
  'Show newsletter popup': {
    path: '/settings?tab=1&subtab=4',
    route: 'Settings -> Comments Widget -> General Settings',
    id: 'general-comments-settings',
  },
  'Toggle hiding the comments widget under a button': {
    path: '/settings?tab=1&subtab=4',
    route: 'Settings -> Comments Widget -> General Settings',
    id: 'general-comments-settings',
  },
  'Hide comment input box': {
    path: '/settings?tab=1&subtab=4',
    route: 'Settings -> Comments Widget -> General Settings',
    id: 'general-comments-settings',
  },
  'Enable comment panel': {
    path: '/settings?tab=1&subtab=4',
    route: 'Settings -> Comments Widget -> General Settings',
    id: 'general-comments-settings',
  },
  'Number of reports after which a comment will be flagged for moderation': {
    path: '/settings?tab=1&subtab=4',
    route: 'Settings -> Comments Widget -> General Settings',
    id: 'general-comments-settings',
  },
  'Default count of loaded comments on the page': {
    path: '/settings?tab=1&subtab=4',
    route: 'Settings -> Comments Widget -> General Settings',
    id: 'general-comments-settings',
  },
  'Comment Character Limit': {
    path: '/settings?tab=1&subtab=4',
    route: 'Settings -> Comments Widget -> General Settings',
    id: 'general-comments-settings',
  },
  'Selected RTE options': {
    path: '/settings?tab=1&subtab=4',
    route: 'Settings -> Comments Widget -> General Settings',
    id: 'general-comments-settings',
  },
  'Spam limit': {
    path: '/settings?tab=1&subtab=4',
    route: 'Settings -> Comments Widget -> General Settings',
    id: 'general-comments-settings',
  },
  'Toxicity limit': {
    path: '/settings?tab=1&subtab=4',
    route: 'Settings -> Comments Widget -> General Settings',
    id: 'general-comments-settings',
  },
  'Theme Color': {
    path: '/settings?tab=1&subtab=4',
    route: 'Settings -> Comments Widget -> General Settings',
    id: 'general-comments-settings',
  },
  'Toggle dark mode theme': {
    path: '/settings?tab=1&subtab=4',
    route: 'Settings -> Comments Widget -> General Settings',
    id: 'general-comments-settings',
  },
  'Open Talk of Town articles in the same tab': {
    path: '/settings?tab=1&subtab=4',
    route: 'Settings -> Comments Widget -> General Settings',
    id: 'general-comments-settings',
  },
  'Emotes header label': {
    path: '/settings?tab=1&subtab=4',
    route: 'Settings -> Comments Widget -> General Settings',
    id: 'general-comments-settings',
  },
  'Emotes vote label': {
    path: '/settings?tab=1&subtab=4',
    route: 'Settings -> Comments Widget -> General Settings',
    id: 'general-comments-settings',
  },
  'Enable ToT Landscape Images': {
    path: '/settings?tab=1&subtab=4',
    route: 'Settings -> Comments Widget -> General Settings',
    id: 'general-comments-settings',
  },
  'Talk of Town label': {
    path: '/settings?tab=1&subtab=4',
    route: 'Settings -> Comments Widget -> General Settings',
    id: 'general-comments-settings',
  },
  'Talk Of Town default article count': {
    path: '/settings?tab=1&subtab=4',
    route: 'Settings -> Comments Widget -> General Settings',
    id: 'general-comments-settings',
  },
  'Talk Of Town default interval count(days)': {
    path: '/settings?tab=1&subtab=4',
    route: 'Settings -> Comments Widget -> General Settings',
    id: 'general-comments-settings',
  },
  'Default sorting option for comments': {
    path: '/settings?tab=1&subtab=4',
    route: 'Settings -> Comments Widget -> General Settings',
    id: 'general-comments-settings',
  },
  'Update external search results source for comments': {
    path: '/settings?tab=1&subtab=4',
    route: 'Settings -> Comments Widget -> General Settings',
    id: 'general-comments-settings',
  },
  'Enable or disable comment bubble button': {
    path: '/settings?tab=1&subtab=4',
    route: 'Settings -> Comments Widget -> General Settings',
    id: 'general-comments-settings',
  },
  'Enable or disable comments for some article': {
    path: '/settings?tab=1&subtab=4',
    route: 'Settings -> Comments Widget -> General Settings',
    id: 'general-comments-settings',
  },
  'Get user comments CSV file by user email': {
    path: '/settings?tab=1&subtab=4',
    route: 'Settings -> Comments Widget -> General Settings',
    id: 'general-comments-settings',
  },
  'Currently enabled widgets': {
    path: '/settings?tab=1&subtab=4',
    route: 'Settings -> Comments Widget -> General Settings',
    id: 'general-comments-settings',
  },
  'Whitelist commenter(s) by Email ID': {
    path: '/settings?tab=1&subtab=5',
    route: 'Settings -> Comments Widget',
    description: `Add the commenters' Email ID which need to be whitelisted.`,
    id: 'whitelist-email-settings',
  },
  'Block word(s)': {
    path: '/settings?tab=1&subtab=6',
    route: 'Settings -> Comments Widget',
    description: `Add the offensive words which need to be blocked. For multiple words separate them by '|'.`,
    id: 'block-words-settings',
  },
  'Block username(s)': {
    path: '/settings?tab=1&subtab=7',
    route: 'Settings -> Comments Widget',
    description: `Add the offensive usernames which need to be blocked. For multiple usernames separate them by '|'.`,
    id: 'block-usernames-settings',
  },
  'Block commenter(s) by IP Address': {
    path: '/settings?tab=1&subtab=8',
    route: 'Settings -> Comments Widget',
    description: `Add the commenter's IP Address which need to be blocked`,
    id: 'block-commenters-by-ip-settings',
  },
  'Block commenter(s) by Email ID': {
    path: '/settings?tab=1&subtab=9',
    route: 'Settings -> Comments Widget',
    description: `Add the commenters' Email IDs which need to be blocked.`,
    id: 'block-commenters-by-email-settings',
  },
  'User Management': {
    path: '/settings?tab=2',
    route: 'Settings -> User Management',
  },
  'Emotes Widget Settings': {
    path: '/settings?tab=3&subtab=0',
    route: 'Settings -> Emotes Settings',
    description: 'Customize Emotes Widget',
    id: 'emotes-widget-settings',
  },
  'Emotes Icons Size (px)': {
    path: '/settings?tab=3&subtab=0',
    route: 'Settings -> Emotes Settings -> Emotes Widget Settings',
    id: 'emotes-widget-settings',
  },
  'Hide emotes recommended articles.': {
    path: '/settings?tab=3&subtab=0',
    route: 'Settings -> Emotes Settings -> Emotes Widget Settings',
    id: 'emotes-widget-settings',
  },
  'Hide first emote': {
    path: '/settings?tab=3&subtab=0',
    route: 'Settings -> Emotes Settings -> Emotes Widget Settings',
    id: 'emotes-widget-settings',
  },
  'Hide second emote': {
    path: '/settings?tab=3&subtab=0',
    route: 'Settings -> Emotes Settings -> Emotes Widget Settings',
    id: 'emotes-widget-settings',
  },
  'Hide third emote': {
    path: '/settings?tab=3&subtab=0',
    route: 'Settings -> Emotes Settings -> Emotes Widget Settings',
    id: 'emotes-widget-settings',
  },
  'Hide fourth emote': {
    path: '/settings?tab=3&subtab=0',
    route: 'Settings -> Emotes Settings -> Emotes Widget Settings',
    id: 'emotes-widget-settings',
  },
  'Hide fifth emote': {
    path: '/settings?tab=3&subtab=0',
    route: 'Settings -> Emotes Settings -> Emotes Widget Settings',
    id: 'emotes-widget-settings',
  },
  'Hide sixth emote': {
    path: '/settings?tab=3&subtab=0',
    route: 'Settings -> Emotes Settings -> Emotes Widget Settings',
    id: 'emotes-widget-settings',
  },
  'Custom Emote Names and Images': {
    path: '/settings?tab=3&subtab=1',
    route: 'Settings -> Emotes Settings',
    description: 'Set custom emote names and images',
    id: 'custom-emote-names-and-images-settings',
  },
  'First Emote Name': {
    path: '/settings?tab=3&subtab=1',
    route: 'Settings -> Emotes Settings -> Custom Emote Names and Images',
    id: 'custom-emote-names-and-images-settings',
  },
  'Second Emote Name': {
    path: '/settings?tab=3&subtab=1',
    route: 'Settings -> Emotes Settings -> Custom Emote Names and Images',
    id: 'custom-emote-names-and-images-settings',
  },
  'Third Emote Name': {
    path: '/settings?tab=3&subtab=1',
    route: 'Settings -> Emotes Settings -> Custom Emote Names and Images',
    id: 'custom-emote-names-and-images-settings',
  },
  'Fourth Emote Name': {
    path: '/settings?tab=3&subtab=1',
    route: 'Settings -> Emotes Settings -> Custom Emote Names and Images',
    id: 'custom-emote-names-and-images-settings',
  },
  'Fifth Emote Name': {
    path: '/settings?tab=3&subtab=1',
    route: 'Settings -> Emotes Settings -> Custom Emote Names and Images',
    id: 'custom-emote-names-and-images-settings',
  },
  'Sixth Emote Name': {
    path: '/settings?tab=3&subtab=1',
    route: 'Settings -> Emotes Settings -> Custom Emote Names and Images',
    id: 'custom-emote-names-and-images-settings',
  },
  'First Emote Image Link': {
    path: '/settings?tab=3&subtab=1',
    route: 'Settings -> Emotes Settings -> Custom Emote Names and Images',
    id: 'custom-emote-names-and-images-settings',
  },
  'Second Emote Image Link': {
    path: '/settings?tab=3&subtab=1',
    route: 'Settings -> Emotes Settings -> Custom Emote Names and Images',
    id: 'custom-emote-names-and-images-settings',
  },
  'Third Emote Image Link': {
    path: '/settings?tab=3&subtab=1',
    route: 'Settings -> Emotes Settings -> Custom Emote Names and Images',
    id: 'custom-emote-names-and-images-settings',
  },
  'Fourth Emote Image Link': {
    path: '/settings?tab=3&subtab=1',
    route: 'Settings -> Emotes Settings -> Custom Emote Names and Images',
    id: 'custom-emote-names-and-images-settings',
  },
  'Fifth Emote Image Link': {
    path: '/settings?tab=3&subtab=1',
    route: 'Settings -> Emotes Settings -> Custom Emote Names and Images',
    id: 'custom-emote-names-and-images-settings',
  },
  'Sixth Emote Image Link': {
    path: '/settings?tab=3&subtab=1',
    route: 'Settings -> Emotes Settings -> Custom Emote Names and Images',
    id: 'custom-emote-names-and-images-settings',
  },
  'Shares Widget': {
    path: '/settings?tab=4&subtab=0',
    route: 'Settings -> Sharebar Settings',
    description: 'Change Shares Widget settings',
    id: 'sharebar-widget-settings',
  },
  'Horizontal Position': {
    path: '/settings?tab=4&subtab=0',
    route: 'Settings -> Sharebar Settings -> Shares Widget',
    id: 'sharebar-widget-settings',
  },
  'Vertical Position (px)': {
    path: '/settings?tab=4&subtab=0',
    route: 'Settings -> Sharebar Settings -> Shares Widget',
    id: 'sharebar-widget-settings',
  },
  'Default Emote': {
    path: '/settings?tab=4&subtab=0',
    route: 'Settings -> Sharebar Settings -> Shares Widget',
    id: 'sharebar-widget-settings',
  },
  'Disable audio player': {
    path: '/settings?tab=4&subtab=0',
    route: 'Settings -> Sharebar Settings -> Shares Widget',
    id: 'sharebar-widget-settings',
  },
  'Disable sharebar emote icon': {
    path: '/settings?tab=4&subtab=0',
    route: 'Settings -> Sharebar Settings -> Shares Widget',
    id: 'sharebar-widget-settings',
  },
  'Disable bookmark button': {
    path: '/settings?tab=4&subtab=0',
    route: 'Settings -> Sharebar Settings -> Shares Widget',
    id: 'sharebar-widget-settings',
  },
  'Disable sharebar more shares button': {
    path: '/settings?tab=4&subtab=0',
    route: 'Settings -> Sharebar Settings -> Shares Widget',
    id: 'sharebar-widget-settings',
  },
  'Disable comments button': {
    path: '/settings?tab=4&subtab=0',
    route: 'Settings -> Sharebar Settings -> Shares Widget',
    id: 'sharebar-widget-settings',
  },
  'Disable PDF download button': {
    path: '/settings?tab=4&subtab=0',
    route: 'Settings -> Sharebar Settings -> Shares Widget',
    id: 'sharebar-widget-settings',
  },
  'Enable custom sharebar styles': {
    path: '/settings?tab=4&subtab=0',
    route: 'Settings -> Sharebar Settings -> Shares Widget',
    id: 'sharebar-widget-settings',
  },
  'Google news URL': {
    path: '/settings?tab=4&subtab=0',
    route: 'Settings -> Sharebar Settings -> Shares Widget',
    id: 'sharebar-widget-settings',
  },
  'Instagram URL': {
    path: '/settings?tab=4&subtab=0',
    route: 'Settings -> Sharebar Settings -> Shares Widget',
    id: 'sharebar-widget-settings',
  },
  'Social Networks': {
    path: '/settings?tab=4&subtab=1',
    route: 'Settings -> Sharebar Settings',
    description: 'Select required social networks',
    id: 'social-networks-settings',
  },
  'Quiz widget general settings': {
    path: '/settings?tab=5&subtab=0',
    route: 'Settings -> Quiz Settings -> Quiz widget general settings',
    description: 'Set up quiz widget general settings',
    id: 'quiz-general-settings',
  },
  'Toggle Quiz widget and Talk of Town popup': {
    path: '/settings?tab=5&subtab=0',
    route: 'Settings -> Quiz Settings -> Quiz widget general settings',
    id: 'quiz-general-settings',
  },
  'Disable results of the Quiz answers': {
    path: '/settings?tab=5&subtab=0',
    route: 'Settings -> Quiz Settings -> Quiz widget general settings',
    id: 'quiz-general-settings',
  },
  'Disable quiz next button': {
    path: '/settings?tab=5&subtab=0',
    route: 'Settings -> Quiz Settings -> Quiz widget general settings',
    id: 'quiz-general-settings',
  },
  'Disable quiz topics': {
    path: '/settings?tab=5&subtab=0',
    route: 'Settings -> Quiz Settings -> Quiz widget general settings',
    id: 'quiz-general-settings',
  },
  'Hide quiz default questions': {
    path: '/settings?tab=5&subtab=0',
    route: 'Settings -> Quiz Settings -> Quiz widget general settings',
    id: 'quiz-general-settings',
  },
  'Change the quiz answers count': {
    path: '/settings?tab=5&subtab=0',
    route: 'Settings -> Quiz Settings -> Quiz widget general settings',
    id: 'quiz-general-settings',
  },
  'Change the quiz AI questions count (min 1, max 7)': {
    path: '/settings?tab=5&subtab=0',
    route: 'Settings -> Quiz Settings -> Quiz widget general settings',
    id: 'quiz-general-settings',
  },
  'Change the quiz default questions count (min 1, max 3)': {
    path: '/settings?tab=5&subtab=0',
    route: 'Settings -> Quiz Settings -> Quiz widget general settings',
    id: 'quiz-general-settings',
  },
  'Change the quiz brand questions start index': {
    path: '/settings?tab=5&subtab=0',
    route: 'Settings -> Quiz Settings -> Quiz widget general settings',
    id: 'quiz-general-settings',
  },
  'Upload Logo': {
    path: '/settings?tab=5&subtab=0',
    route: 'Settings -> Quiz Settings -> Quiz widget general settings',
    id: 'quiz-general-settings',
  },
  'Block Words (Quiz)': {
    path: '/settings?tab=5&subtab=0',
    route: 'Settings -> Quiz Settings -> Quiz widget general settings',
    id: 'quiz-general-settings',
  },
  'Quiz brands': {
    path: '/settings?tab=5&subtab=1',
    route: 'Settings -> Quiz Settings',
    description: 'Create quiz brands',
    id: 'brands-for-quiz-settings'
  },
  'Quiz brand questions': {
    path: '/settings?tab=5&subtab=2',
    route: 'Settings -> Quiz Settings',
    description: 'Create quiz brand questions and answers',
    id: 'brand-questions-for-quiz-settings'
  },
};
